// import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '../views/login.vue';

// Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      noLayoutBackground: true,
      noLayoutSpace: true,
      noLayout: false,
      isPublic: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
