
import Vue from 'vue';
export default Vue.extend({
  methods: {
    localeChange() {
      if (this.$microState) {
        this.$microState.setGlobalState({ locale: this.$i18n.locale });
      }
    }
  }
});
