
import Vue from 'vue';
import { Ns, STORAGE_KEYS, STORE_NS } from '../consts';
export default Vue.extend({
  components: {},
  computed: {},
  data() {
    return {
      // 这是登录表单的绑定对象
      loginForm: {
        username: '',
        password: ''
      },
      // 这是表单的验证规则对象
      loginFormRules: {
        // 验证用户名是否合法
        username: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '长度在 3 到 10 个字符',
            trigger: 'blur'
          }
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    // 点击重置按钮，重置登录表单
    resetLoginForm() {
      //   console.log(this);
      this.loginForm.username = '';
      this.loginForm.password = '';
    },
    //  预验证
    login() {
      // this.$refs.loginFormRef.validate(async valid => {
      //   if (!valid) return;
      // const { data: res } = await this.$http.post('login', this.loginForm);
      if (
        this.loginForm.username === 'admin' &&
        this.loginForm.password === '123456'
      ) {
        console.log('-----------------------------');
        const res = {
          meta: { status: 200 },
          data: {
            token:
              'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImNyZWF0ZWQiOjE1OTgyNDk1MTc2MjIsImV4cCI6MjY5ODg1NDMxN30.mvQl38ags-Uh0efcQEkkj_iF5sNF8u6lB-RIyGLnJnQQTO7jBHWlt5vkl2MXnfAYCU3wrEkR6AzKxi5EQa9sWA'
          }
        };
        if (res.meta.status !== 200) return this.$message.error('登录失败');
        this.$message.success('登录成功');
        document.cookie = 'boeToken=' + res.data.token;
        localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, res.data.token);
        localStorage.setItem(
          STORAGE_KEYS.ACCESS_TOKEN_LOAD_AT,
          new Date().getTime().toString()
        );
        // const url = this.$route.query.r
        this.$router.push('/auth-callback');

        //  1、将登陆成功后的token保存到客户端的sessionStorage中
        //   1.1 项目中除了登录之外的其他API接口,必须在登录之后才能访问
        //   1.2 token只应该在当前网站打开期间生效
        // console.log(res);
        // window.sessionStorage.setItem('token', res.data.token);
        //  2、通过编程式导航跳到后台主页，路由地址是/home
        // this.$router.push('/home');
      } else {
        this.$message.error('账号或密码错误！');
      }
      //   });
    }
  }
});
