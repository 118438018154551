import en from './en';
import zh from './zh-CN';
import ja from './ja';
import elen from 'element-ui/lib/locale/lang/en.js';
import elzh from 'element-ui/lib/locale/lang/zh-CN.js';
import elja from 'element-ui/lib/locale/lang/ja.js';
import { mergeLang, createI18n } from 'ui-components';

export default createI18n('zh', {
  en: mergeLang(en, elen),
  zh: mergeLang(zh, elzh),
  ja: mergeLang(ja, elja),
});
