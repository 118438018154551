const isDev = process.env.NODE_ENV === 'development';

/**
 * 路由监听
 * @param {*} routerPrefix 前缀
 */
// function genActiveRule(routerPrefix: string) {
//   return (location: Location) => location.pathname.startsWith(routerPrefix);
// }

/**
 * 根据env生成子应用路径
 */
function genSubAppEntry(appPortItem: string[]) {
  return isDev && appPortItem.length >= 2
    ? '//localhost:' + appPortItem[1]
    : process.env.VUE_APP_SUB_APP_PATH + appPortItem[0];
}

const testAppPorts = [['hcm-ui']];

const appPorts = [
  ['hcm-ui', '8913'],
  ['portal-ui', '8901'],
  ['authority-ui', '8902'],
  ['masterdata-ui', '8903'],
  ['business-ui', '8904'],
  ['r2r-ui', '8905'],
  ['ar-ui', '8906'],
  ['expense-ui', '8907'],
  ['fs-ui', '8908'],
  ['invoice-ui', '8909'],
  ['payment-ui', '8910'],
  ['share-ui', '8911'],
  ['mq-ui', '8912']
];

// const appPorts = [
//   ['hcm-ui'],
//   ['portal-ui'],
//   ['authority-ui'],
//   ['masterdata-ui'],
//   ['business-ui'],
//   ['r2r-ui'],
//   ['ar-ui'],
//   ['expense-ui'],
//   ['fs-ui'],
//   ['invoice-ui'],
//   ['payment-ui'],
//   ['share-ui'],
//   ['mq-ui']
// ];

if (process.env.VUE_APP_HAS_TEMPLATE === 'true') {
  appPorts.push(['template-ui']);
}
console.log('1111111111111111111111111111111111111111111111111111');
console.log(
  appPorts.map(item => {
    return {
      name: item[0],
      entry: genSubAppEntry(item),
      activeRule: `${process.env.VUE_APP_PUBLIC_PATH || '/'}${item[0]}`
    };
  })
);

export default appPorts.map(item => {
  return {
    name: item[0],
    entry: genSubAppEntry(item),
    activeRule: `${process.env.VUE_APP_PUBLIC_PATH || '/'}${item[0]}`
  };
});
