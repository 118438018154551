import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import Vue from 'vue';
import ElementUI from 'element-ui';
import {
  initGlobalState,
  MicroAppStateActions,
  registerMicroApps,
  setDefaultMountApp,
  start
} from 'qiankun';
import App from './App.vue';
import store from './store';
import i18n from './i18n';
import router from './router';
import configLayout, {
  STORE_NS,
  Ns,
  configAuth,
  AuthType,
  BASE_PATH_KEYS,
  isIdle
} from 'ui-components';
import microApps from './micro-apps';
import 'ui-styles/sass/index.scss';
import 'ui-icons/iconfonts/iconfont.css';
import './assets/app.scss';

const publicPath = process.env.VUE_APP_PUBLIC_PATH;
const isDev = process.env.NODE_ENV === 'development';

let app: Vue;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(
  configLayout({
    store,
    i18n,
    disableOidc: process.env.VUE_APP_DISABLE_OIDC === 'true',
    disableAuthority: process.env.VUE_APP_DISABLE_OIDC === 'true',
    apiBasePath: new Map([
      [
        BASE_PATH_KEYS.AUTHORITY,
        process.env.VUE_APP_AUTHORITY_API_BASE_PATH || ''
      ],
      [BASE_PATH_KEYS.MENU, process.env.VUE_APP_MENU_API_BASE_PATH || ''],
      [
        BASE_PATH_KEYS.TRANSACTION,
        process.env.VUE_APP_TRANSACTION_API_BASE_PATH || ''
      ]
    ]),
    publicPath: publicPath,
    onNavigate: url => (url ? url.replace('/fssc/', publicPath || '/') : url),
    isMain: true
  })
);

Vue.use(
  configAuth({
    type: process.env.VUE_APP_AUTH_TYPE as AuthType,
    settings: process.env.VUE_APP_AUTH_SETTINGS,
    store
  })
);

function authCheckAccess() {
  if (store && store.hasModule(STORE_NS.AUTH)) {
    console.log('main app check access');
    store.dispatch(Ns(STORE_NS.AUTH, STORE_NS.AUTH_ACTIONS.CHECK_ACCESS), {});
  }
}

function loadLayout() {
  if (store && store.hasModule(STORE_NS.LAYOUT)) {
    console.log('main app load layout');
    store.dispatch(Ns(STORE_NS.LAYOUT, STORE_NS.LAYOUT_ACTIONS.LOAD));
  }
}

/**
 * 渲染函数
 * appContent 子应用html内容
 * loading 子应用加载效果，可选
 */
function render({ appContent, loading }: any = {}) {
  if (!app) {
    app = new Vue({
      el: '#container',
      router,
      store,
      i18n,
      data() {
        return {
          content: appContent,
          loading
        };
      },
      render(h) {
        return h(App, {
          props: {
            content: appContent,
            loading: loading
          }
        });
      }
    });
  } else {
    (app as any).content = appContent;
    (app as any).loading = loading;
  }
}

function initApp() {
  render({ appContent: '', loading: true });
}

initApp();

function mapMicroApps(apps: any[]) {
  const props = {
    locale: app.$i18n.locale,
    publicPath: publicPath,
    homeUrl: microApps[0].activeRule,
    checkAccessAt: new Date().getTime(),
    loadMenuAt: new Date().getTime(),
    originalTitle: document.title,
    title: '',
    loadLayout: loadLayout,
    isIdle: isIdle
  };
  const actions = initGlobalState(props) as MicroAppStateActions;

  function onCheckAccessAtChange(state: any, prev: any) {
    if (
      props &&
      props.checkAccessAt &&
      (!prev || state.checkAccessAt !== prev.checkAccessAt)
    ) {
      authCheckAccess();
    }
  }

  function onloadMenuAtChange(state: any, prev: any) {
    if (
      props &&
      props.loadMenuAt &&
      (!prev || state.loadMenuAt !== prev.loadMenuAt)
    ) {
      loadLayout();
    }
  }

  function onTitleChange(state: any, prev: any) {
    if (state && state.title && (!prev || state.title !== prev.title)) {
      document.title = state.title;
    }
  }

  Vue.prototype.$microState = { state: props, ...actions };
  actions.onGlobalStateChange((state, prev) => {
    // state: 变更后的状态; prev 变更前的状态
    console.log('main app state change', state, prev);

    onCheckAccessAtChange(state, prev);
    onloadMenuAtChange(state, prev);
    onTitleChange(state, prev);
  });

  return apps.map(item => {
    return {
      ...item,
      container: '#subapp-viewport',
      props: props
    };
  });
}

console.log('2222222222222222222222222222222222');
console.log(mapMicroApps(microApps));
// 注册子应用
registerMicroApps(mapMicroApps(microApps), {
  beforeLoad: [
    app => {
      console.log('before load', app);
      return Promise.resolve();
    }
  ],
  beforeMount: [
    app => {
      console.log('before mount', app);
      return Promise.resolve();
    }
  ],
  afterUnmount: [
    app => {
      console.log('after unload', app);
      return Promise.resolve();
    }
  ]
});

setDefaultMountApp(microApps[0].activeRule);
start();
