/**
 * 预定义基础路径键名
 */
export const BASE_PATH_KEYS = {
  AUTHORITY: 'AUTHORITY',
  MENU: 'MENU',
  TRANSACTION: 'TRANSACTION',
  MASTER_DATA: 'MASTER_DATA'
};

/**
 * 本地存储键名
 */
export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  ACCESS_TOKEN_LOAD_AT: 'accessTokenLoadAt'
};

/**
 * Cookies键名
 */
export const COOKIES_KEYS = {
  BU_LANG: 'bu-lang'
};

/**
 * Vuex Store命名空间
 */
export const STORE_NS = {
  LAYOUT: 'layoutStore',
  LAYOUT_STATE: {
    IS_LOADING: 'isLoading',
    EMPLOYEE: 'employee',
    EMPLOYEE_NAME: 'employeeName'
  },
  LAYOUT_ACTIONS: {
    LOAD: 'load',
    LOAD_ON_MOUNTED: 'loadOnMounted',
    LOAD_FOR_MICRO: 'loadForMicro',
    SHOW_LOADING: 'showLoading',
    HIDE_LOADING: 'hideLoading',
    CHECK_PERMISSIONS: 'checkPermissions'
  },
  LAYOUT_MUTATIONS: {
    CHANGE_HOME_URL: 'changeHomeUrl',
    RESET: 'reset'
  },
  LAYOUT_GETTERS: {
    GET_MENU_ITEMS: 'getMenuItems',
    GET_MENU_SUB_ITEMS: 'getMenuSubItems',
    GET_EMPLOYEE: 'getEmployee',
    GET_EMPLOYEE_NAME: 'getEmployeeName'
  },
  OIDC: 'oidcStore',
  OIDC_ACTIONS: {
    OIDC_CHECK_ACCESS: 'oidcCheckAccess',
    OIDC_SIGN_IN_CALLBACK: 'oidcSignInCallback',
    REMOVE_OIDC_USER: 'removeOidcUser'
  },
  C2TC: 'c2tcStore',
  C2TC_ACTIONS: {
    CHECK_ACCESS: 'checkAccess',
    SET_REFERESH_TOKEN: 'setRefereshToken',
    SIGN_IN_CALLBACK: 'signInCallback',
    REMOVE_USER: 'removeUser'
  },
  AUTH: 'authStore',
  AUTH_ACTIVE_ROUTE: 'auth_active_route',
  AUTH_MUTATIONS: {
    CHANGE_GLOBAL_STORE: 'changeGlobalStore'
  },
  AUTH_ACTIONS: {
    CHECK_ACCESS: 'checkAccess',
    SIGN_IN_CALLBACK: 'signInCallback',
    REMOVE_USER: 'removeUser'
  }
};

/**
 * 组合带命名空间的Vuex Store
 * @param namespace
 * @param member
 * @returns
 */
export function Ns(namespace: string, member: string) {
  return `${namespace}/${member}`;
}

/**
 * form-button组件内置按钮名称
 */
export const FORM_BUTTONS = {
  /** 保存 */
  SAVE: 'save',
  /** 删除 */
  DELETE: 'delete',
  /** 立即知会 */
  NOTICE: 'notice',
  /** 打印 */
  PRINT: 'print',
  /** 提交 */
  SUBMIT: 'submit',
  /** 会签 */
  COUNTERSIGN: 'countersign',
  /** 加签审批 */
  SIGN: 'sign',
  /** 审批 */
  APPROVE: 'approve',
  /** 驳回 */
  REJECT: 'reject',
  /** 退回 */
  BACK: 'back',
  /** 撤回 */
  WITHDRAW: 'withdraw',
  /** 收回 */
  UNDO: 'undo',
  /** 审核通过 */
  PASS: 'pass',
  /** 认领 */
  CLAIM: 'claim',
  /** 已阅 */
  READED: 'readed',
  /** 会签完成 */
  COMPLETE_SIGN: 'completeSign',
  /** 预测 */
  PROCESS_TREND: 'processTrend'
};

/**
 * form-button组件内置按钮名称组
 */
export const FORM_BUTTON_GROUPS = {
  CREATE_GROUP: [
    FORM_BUTTONS.SAVE,
    FORM_BUTTONS.DELETE,
    FORM_BUTTONS.NOTICE,
    FORM_BUTTONS.PRINT,
    FORM_BUTTONS.SUBMIT,
    FORM_BUTTONS.PROCESS_TREND
  ],
  APPROVE_GROUP: [FORM_BUTTONS.COUNTERSIGN, FORM_BUTTONS.APPROVE],
  SHARE_GROUP: [
    FORM_BUTTONS.SAVE,
    FORM_BUTTONS.COUNTERSIGN,
    FORM_BUTTONS.REJECT,
    FORM_BUTTONS.PASS
  ]
};
